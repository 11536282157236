import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Get_All_Admins } from "../../../Service/superadmin.service"


export const GetAllAdmins = createAsyncThunk("superadmin/all_admins", async (data) => {


  try {
    const res = await Get_All_Admins(data)
    return await res;
  } catch (err) {
    console.log("error", err);
  }
});

const SuperadminSlice = createSlice({
  name: "SuperadminSlice",
  initialState: {
    isLoading: false,
    alladmins: [],
    connectsession: [],

  },

  recuders: {},
  extraReducers: {

    [GetAllAdmins.pending]: (state, action) => {
      console.log("Pending")
      // state.isLoading = true;
    },
    [GetAllAdmins.fulfilled]: (state, { payload }) => {
      //  state.isLoading = false;
      return { ...state, alladmins: payload };
    },
    [GetAllAdmins.rejected]: (state) => {
      // state.isError = true;
    },
  },
})

export const getAlladmins = (state) => state.SuperadminSlice.alladmins;
// export const connectsessiondata = (state) => state.LiveMarketData.connectsession;

export default SuperadminSlice;


