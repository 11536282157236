
import React, { useState, useEffect } from 'react';
import { fDateTime } from "../../../Utils/DateFormat"
import ShowProfitLoss from "./ShowProfitLoss"
import Model from "../../../component/ReusableComponents/Model/Model"
import { CreateSocketSession, ConnctSocket } from "../../../Service/alices_socket.service"
import $ from "jquery";



const AcountInfo = ({ getPositions }) => {
  const { data, status } = getPositions


  // for model show hide
  const [modalOpen, setModalOpen] = useState(false);

  const [shoeMore, setShowMore] = useState(false);






  var CreatechannelList = "";
  if (data != undefined &&  data != 'Session Expired Please trading re-login' && data != 'Please On Trading First') {
    data != undefined && data?.map((item) => {
      CreatechannelList += `${item.exchange}|${item.instrumentToken}#`
    })

  }


  const ShowMoreDetails = (item) => {
    setShowMore(item)
    setModalOpen(true)
  }


  const ShowPosition = async () => {

    var userId = "883538"
    var userSession = "6zMvfGDJIQPH8nTCzjTerlsAa7jHIpiTJfWJONMIWRITi3ctpNvztM8BdA829MdmSrPQU1eQCWObIuKPKhz4KQDEsCY93n55jHvNnO6zrEZI9WxI60H7FRZS1WYlHLATKthdlfTt5iFRIxRa92SECeugxAMhGCprWqGxkkR32oAOA1zcl7YQzrB2GW02mX7X7CBfxyFFRmS8fAk84BBc297dZcPnDC32BgSBjBeJAtfGLTzbNKaFeGQyXOTjSXMU"

    let type = { loginType: "API" }

    let channelList = CreatechannelList.slice(0, -1)
    console.log("channelList ShowPosition" ,channelList);


    const res = await CreateSocketSession(userId, userSession, type,)

    if (res.data.stat) {
      const handleResponse = (response) => {


       // console.log("response", response);
        if (response.tk) {

          //_______________________  For Calculate Live LTP ________________________


          if (response.lp != undefined) {
            $(".live_data" + response.tk).html(response.lp);

            const get_stock_price = $(".get_stock_price" + response.tk).html();

            if (parseFloat(response.lp) < parseFloat(get_stock_price)) {
              $('.live_data' + response.tk).css({ "color": "Red" });
              $('.live_data' + response.tk).append('&#8595;')
            } else if (parseFloat(response.lp) > parseFloat(get_stock_price)) {
              $('.live_data' + response.tk).css({ "color": "green" });
              $('.live_data' + response.tk).append('&#8593;')
            } else if (parseFloat(response.lp) === parseFloat(get_stock_price)) {
              $('.live_data' + response.tk).css({ "color": "black" });
            }

            //_______________________  //  For Calculate UnRealised Profit Loss    _______________________

            const get_lot_qty = $(".get_lot_qty" + response.tk).html();

            const CalUnPNL = ((parseFloat(response.lp) - parseFloat(get_stock_price)) * parseInt(get_lot_qty)).toFixed(2)
            $(".Cal_UnPNL" + response.tk).html(CalUnPNL == 0 ? "-" : CalUnPNL)
            if (parseFloat(CalUnPNL) < 0) {
              $('.Cal_UnPNL' + response.tk).css({ "color": "Red" });
              $('.Cal_UnPNL' + response.tk).append('&#8595;')
            } else if (parseFloat(CalUnPNL) > 0) {
              $('.Cal_UnPNL' + response.tk).css({ "color": "green" });
              $('.Cal_UnPNL' + response.tk).append('&#8593;')
            } else {
              $('.Cal_UnPNL' + response.tk).css({ "color": "black" });
            }


            //_______________________  //  For Calculate TOTal Profit Loss    _______________________

            var total = totalPL(data)
            console.log("total ", total);
            $(".total_Pl").html(total == 0 ? "-" : total.toFixed(2));
            if (total > 0) {
              $('.total_Pl').css({ "color": "green" }).append('&#8593;')
            }
            else if (total < 0) {
              $('.total_Pl').css({ "color": "red" }).append('&#8595;')
            }
            else if (total == 0) {
              $('.total_Pl').css({ "color": "black" });
            }
          }
          else {
            $(".live_data" + response.tk).html(response.lp);
          }
        }
      };
      await ConnctSocket(userId, userSession, handleResponse, channelList);
    }
  }


  useEffect(() => {
    ShowPosition()
  }, [data])






  const totalPL = (maindata) => {
    var totalPl = 0

    maindata.map((item) => {
      var live_price_lp = $('.live_data' + item.instrumentToken).html();
      totalPl += (parseFloat(live_price_lp) - parseFloat(item.averageStockPrice)) * parseInt((item.netTrdQtyLot)).toFixed(2)
    })


    return totalPl
  }










  return (
    <div>

      <ShowProfitLoss LivePl="total_Pl" />
      {/* <button onClick={() => test()}>RunSocket</button> */}


      <h6 className='test'></h6>
      <div className='row align-items-center my-3'>
        {/* <div className='col-lg-4'><h4 className='mb-0'>View Details</h4></div>
        <div className='col-lg-4'><button type="button" class="btn btn-outline-primary"><i className="fa-solid fa-download"></i>Download CSV</button></div>
        <div className='col-lg-4'>
          <div className="search">
            <span className="fa fa-search" />
            <input placeholder="Search term" />
          </div>
        </div> */}

      </div>
      {data== "Please On Trading First" ?  <h6 className='text-center'>Please On Trading First</h6> :  data == "Session Expired Please trading re-login" ? <h6 className='text-center'>Session Expired Please trading re-login</h6>
        : <>

          {data && data.map((item) => {
            return <>


              <div className='row border rounded my-3  boxes-row'>
                <div className='col-lg-5'>
                  <div className='d-flex '>
                    <span class="badge bg-light text-dark ms-0 " style={{ height: '21px;' }}>{item.exchange}</span>
                    <h6 className='ms-2'>{item.instrumentName}</h6>
                  </div>
                  <p>1 LOT <i class="fa-regular fa-arrow-down-from-line"></i>= {item.lotsize}</p>
                  <span class="badge btn-outline-success">Buy</span>
                  <span class="badge btn-outline-warning ms-2">
                    {item.segment == "EQ" ? "EQUITY " : item.segment == "O" ? "Option" : ''}
                  </span>
                  <span class="badge btn-outline-info ms-2">
                    {item.marginType == "S" ? "Span " : item.marginType == "N" ? "Normal" : ''}
                    Margin</span>
                </div>
                <div className='col-lg-6'>
                  <div className='row'>
                    <div className='col-lg-4 float-lg-right'>
                      <p className='mb-0'>TRADED QTY</p>
                      <h6>{item.netTrdQtyLot}</h6>
                    </div>
                    <div className='col-lg-4 float-lg-right'>
                      <p className='mb-0'>AVG. PRICE</p>
                      {/* <h6>{item.entryprice}</h6> */}
                      <h6>{(item.averageStockPrice).toFixed(2)}</h6>
                    </div>
                    <div className='col-lg-4 float-lg-right'>
                      <p className={`mb-0 `}>LTP</p>
                      <h6 className={"live_data" + item.instrumentToken}></h6>

                      {/* <h6 className={(`IST_${item.exchange}|${item.instrumentToken}`)}>{getLTPPRIC(item.exchange, item.instrumentToken)}</h6> */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-4 float-lg-right'>
                      <p className='mb-0'>TRADED VALUE</p>
                      <h6>{item.netTrdValue}</h6>
                    </div>
                    <div className='col-lg-4 float-lg-right'>
                      <p className='mb-0'>REALIZED P&L</p>
                      <h6>{item.realizedPL}</h6>
                    </div>
                    <div className='col-lg-4 float-lg-right'>
                      <p className='mb-0'>UNREALIZED P&L</p>
                      {/*  UNREALIZED P&L = entryPrice - LTP * NetTradeQty  */}

                      <p style={{ display: "none" }} className={"get_stock_price" + item.instrumentToken} > {item.averageStockPrice}</p>

                      <p style={{ display: "none" }} className={"get_lot_qty" + item.instrumentToken} > {item.netTrdQtyLot}</p>

                      <h6 className={"Cal_UnPNL" + item.instrumentToken}  >- </h6>
                    </div>
                  </div>
                </div>




                <div className='col-lg-1'>
                  <button
                    className="border-0 "
                    onClick={() => ShowMoreDetails(item)}
                  >
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                </div>
              </div >
            </>
          })}
        </>

      }
      <Model isOpen={modalOpen} size={"lg"} onClose={() => setModalOpen(false)} title="Open Position  Details"
        content={
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6  border border-light">
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Margin Utlised</h6>
                    <h6>{shoeMore.marginUse}</h6>
                  </div>
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Spread </h6>
                    <h6>0.00</h6>
                  </div>
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Leverage</h6>
                    <h6>0.00</h6>
                  </div>
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Realized P&L</h6>
                    <h6>0.00</h6>
                  </div>
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Carry Forward Position</h6>
                    <h6>0.00</h6>
                  </div>
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Carry Forward Postion Value</h6>
                    <h6>0.00</h6>
                  </div>
                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>RBI Reference Rate</h6>
                    <h6>0.00</h6>
                  </div>

                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Premium</h6>
                    <h6>0.00</h6>
                  </div>

                </div>
                <div className="col-6 border border">
                  <div className='d-flex '>
                    <span class="badge bg-light text-dark ms-0" style={{ height: '21px;' }}>NSE</span>
                    <h6 className='ms-2 flex-grow-1'>
                      JPPOWER-EQ
                      {/* {item.instrumentName} */}
                    </h6>
                    <h6 className={`ms - 2 ${parseInt(shoeMore.percentChange) < 0 ? "text-danger" : "text-success"}`}>
                      <span className='me-4'>
                        {shoeMore.LTP}
                      </span>
                      {shoeMore.netChange}  ({shoeMore.percentChange}%)
                      {/* {item.instrumentName} */}
                    </h6>

                  </div>

                  <span class="badge btn-outline-warning ms-2">                {shoeMore.segment == "EQ" ? "EQUITY " : shoeMore.segment == "O" ? "Option" : ''}
                  </span>
                  <span class="badge btn-outline-warning ms-2">  {shoeMore.marginType == "S" ? "Span " : shoeMore.marginType == "N" ? "Normal" : ''}
                    Margin</span>



                  <hr />
                  <div className='d-flex align-item-center '>
                    {/* <h6 className='flex-grow-1'>Traded 1 Lot = 25 qty</h6> */}
                    <h6 className='flex-grow-1'>Traded Quantity</h6>
                    <h6>{shoeMore.netTrdQtyLot}</h6>
                  </div>

                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Traded Price</h6>
                    <h6>{shoeMore.entryprice}</h6>
                  </div>

                  <div className='d-flex align-item-center '>
                    <h6 className='flex-grow-1'>Traded Value</h6>
                    <h6>{shoeMore.netTrdValue}</h6>
                  </div>



                </div>
              </div>
            </div>




          </>
        }

      />





    </div >
  )
}

export default AcountInfo




