
import React, { useEffect, useState } from 'react'
import DataTable from '../../../Utils/Datatables';
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdminSystem } from "../../../ReduxStore/Slice/AdminSlice/System/SystemSlice"


const System = () => {
  const [first, setfirst] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const { data, isLoading, } = useSelector((state) => state && state?.Systemslice)


  var tableData

  const GetSystemDta = async () => {

    await dispatch(AdminSystem(AdminToken)).then((res) => {
      //  setfirst(res.payload)
      tableData = res.payload
    })

  }
  // console.log("selector", data);

  useEffect(() => {
    GetSystemDta()
  }, [dispatch])

  if (!data) {
    return []; // or display a loading state, a placeholder, or an empty message
  }


  const columns = [
    {
      name: 'Company Name',
      selector: row => row.company_name,
      width: "170px"
    },
    {
      name: 'Company Email',
      selector: row => row.company_email,
      width: "170px"

    },
    {
      name: 'Company CC',
      selector: row => row.company_cc,
      width: "170px"

    },
    {
      name: 'Company BCC',
      selector: row => row.company_bcc,
      width: "170px"

    },
    {
      name: 'SMTP Port',
      selector: row => row.smtp_port,
      width: "120px"

    },
    {
      name: 'SMTP Host',
      selector: row => row.smtp_host,
      width: "180px"

    },
    {
      name: 'Actions',
      width: "170px",
      selector: row =>
        <>
          <div className='actiontd'>
            <Link to={`editsystem/${row.id}`} state={row}>
              <i class="fa-solid fa-pen-to-square" />
            </Link>
            {/* //<a href='#' className='mx-2'><i class="fa-regular fa-eye"></i></a> */}
            {/* <a href='#'><i class="fa-solid fa-trash"></i></a> */}
          </div>
        </>
      ,
      width: "172px"

    },


  ]



  return (
    <div><div className="main-panel">
      <div className="content ">
        <div className="panel-header bg-primary-gradient">
          <div className="py-5 page-inner">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <h2 className="pb-2 text-white fw-bold">System</h2>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row row-card-no-pd  shadow-lg mt--2">
            <div className='data-table-div'>
              {/* <Link to='/admin/client/add' className='btn btn-primary'> <i class="fa-solid fa-plus"></i> add Client</Link> */}
              <DataTable
                columns={columns} data={data}
              />
            </div>
          </div>
        </div>
      </div>
    </div></div>
  )
}

export default System


