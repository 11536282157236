import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from '../../../Utils/Datatables';
import {fDateTime} from '../../../Utils/DateFormat';
import { toast } from "react-toastify";
import AlertToast from "../../../Utils/Alert_Toast"
import { Link } from "react-router-dom";
import { GetAllAdmins, getAlladmins } from "../../../ReduxStore/Slice/SuperadminSlice/SuperadminSlice"


const Admins = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;


    const { data, status } = useSelector(getAlladmins)





    const GetAllClients = async () => {
        await dispatch(GetAllAdmins(AdminToken))
    }

    useEffect(() => {
        GetAllClients()
    }, [dispatch])



    //   const RemoveUser = async (userid) => {
    //     // console.log("test", userid);
    //     await dispatch(Remove_One_User({ id: userid, token: AdminToken })).then((res) => {
    //       // console.log("repso", res);
    //       if (res.meta.requestStatus === "fulfilled") {
    //         toast.success(res.payload.data)
    //         setRerender(!rerender)
    //       }
    //     })


    //   }


    //   const ShowUsers = async (id , username) => {
    //     setShowMasterUsername(username)
    //     setModalOpen(true)

    //     await dispatch(GetClientAcordingItMaster({ id: id, AdminToken: AdminToken }))

    //   }

    const columns = [

        {
            name: 'Username',
            selector: row => row.username,
            width: "140px"
        },
        {
            name: 'Full Name',
            selector: row => row.fullname,
            width: "140px"
        },
        {
            name: 'show_password',
            selector: row => row.show_password,
            width: "140px"
        },
        {
            name: "Contact ",
            selector: row => row.phone_number,
            width: "170px"

        },
        {
            name: 'Email',
            selector: row => row.email,
            width: "170px"

        },
        {
            name: 'Start Date',
            selector: row => fDateTime(row.createdAt),
            width: "170px"
        },
        // {
        //   name: 'Actions',
        //   selector: row =>
        //     <>
        //       <div className='actiontd'>

        //         <a data-toggle="tooltip" data-placement="top" title="Show Clients">

        //           <i class="fa-sharp fa-solid fa-people-arrows mx-3 " style={{ fontSize: "16px" }} onClick={() => ShowUsers(row.id, row.username)}></i>
        //         </a>

        //         <Link to={`/admin/master/edit/${row.id} `} state={row}>
        //           <i class="fa-solid fa-pen-to-square" />
        //         </Link>
        //         {/* //<a href='#' className='mx-2'><i class="fa-regular fa-eye"></i></a> */}

        //         <a data-toggle="tooltip" data-placement="top" title="Delete "><i class="fa-solid fa-trash mx-3" onClick={() => RemoveUser(row.id)}></i></a>


        //       </div>
        //     </>
        //   ,
        //   width: "172px"

        // },


    ]



    return (
        <div>

            <div className="main-panel">
                <div className="content ">
                    <div className="panel-header bg-primary-gradient">
                        <div className="py-5 page-inner">
                            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                                <h2 className="pb-2 text-white fw-bold">Master Clients</h2>
                            </div>
                        </div>
                    </div>
                    <div className="page-inner mt--5">
                        <div className="row row-card-no-pd  shadow-lg mt--2">
                            <div className='data-table-div'>
                                {/* <Link to='/admin/master/add' className='btn btn-primary'> <i class="fa-solid fa-plus"></i> Add Master</Link> */}
                                <DataTable
                                    columns={columns}
                                    data={data && data}
                                />

                                <AlertToast position="top-center" />


                            

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </Content  > */}
        </div>
    )
}

export default Admins