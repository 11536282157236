

import React, { useState, useEffect, Component } from 'react';
import ReusedTradeHistory from "../../../component/ReusableComponents/TradHistory/TradHistory"
import { GetTradingStatus, tradingstatus } from "../../../ReduxStore/Slice/UsersSlice/dashboardSlice"

import { useDispatch, useSelector } from "react-redux";
import {getUserTradeReportdata , tradereportData } from "../../../ReduxStore/Slice/UsersSlice/CommonSlice/CommonSlice"






const TradingReport = () => {

  const dispatch = useDispatch()




  const userid = JSON.parse(localStorage.getItem('user_details')).id;
  const AdminToken = JSON.parse(localStorage.getItem('user_details')).accessToken;
  const trading_status = useSelector(tradingstatus && tradingstatus)
  const getTradeReport = useSelector(tradereportData)











  const userTradReport = () => {
    dispatch(getUserTradeReportdata({ id: userid, AdminToken: AdminToken }))
  }

  useEffect(() => {
    userTradReport()

  }, [dispatch, trading_status && trading_status.trading_type])



  useEffect(() => {
    dispatch(GetTradingStatus({ id: userid, AdminToken: AdminToken }))
  }, [dispatch])




  return (
    <>
      {/* {getTradehistory && getTradehistory.length === 0 ?
        <p>No Data Found1</p>
        : */}
      <ReusedTradeHistory
        tableData={getTradeReport}
        title="Master Trade Report" />

      {/* }  */}




    </>
  )
}

export default TradingReport