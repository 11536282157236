import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {admin_dashboard } from "../../../../Service/admin.service"



export const AdminDashboard_Data = createAsyncThunk("admin/dashboard_data", async ( token) => {
  try {
    const res = await admin_dashboard( token)
    if (res.status) {
      return await res?.data;
    }
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});




const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    isLoading: false,
    // data: [],
   admin_dashboard:[]
  },
  recuders: {},
  extraReducers: {

    [AdminDashboard_Data.pending]: (state, action) => {
      console.log("Pending")
    },
    [AdminDashboard_Data.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
     
      return { ...state, admin_dashboard: [payload] };
    },
    [AdminDashboard_Data.rejected]: (state) => {
      state.isError = true;
      console.log("reject dashboard")

    },
   
  },
})

export const getDashboardData = (state) => state.aaDashboardSlice.admin_dashboard;

export default DashboardSlice;

