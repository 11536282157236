import React, { useState } from 'react'
import axios from "axios";

// import Files
import * as Config from "../Utils/Config";
import { header } from "../Utils/api_header";



// Admin Dashboard 
export async function admin_dashboard(token) {
    try {
        const res = await axios.get(`${Config.base_url}admin/dashboard`, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}





export async function admin_system(token) {
    try {
        const res = await axios.get(`${Config.base_url}system`, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }
}

export async function Update_admin_system(data, token) {
    console.log("data" , data);
    try {
        const res = await axios.post(`${Config.base_url}system/update`, data , {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }
}







//  Get All Clients
export async function admin_All_Masters(token) {
    try {
        const res = await axios.get(`${Config.base_url}admin/getMasters`, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}
//  Add Client
export async function admin_Add_Client(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}admin/userAdd`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;

        // console.log("error", err);
        // custom error
    }

}



export function Find_One_Child_User(data, token) {
    return axios.get(`${Config.base_url}admin/getOneUser/${data}`, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

export function Remove__User(data, token) {

    return axios.get(`${Config.base_url}admin/delete/${data}`, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


// update user master/users

export function Update_master_or_child(data, token) {
    return axios.post(`${Config.base_url}admin/updateUser`, data, {
        headers: header(token),
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



// ----------------------------------------------  Masters   ---------------------------------------------------




//  Get All Clients
export async function admin_All_Masters_Name(token) {
    try {
        const res = await axios.get(`${Config.base_url}admin/getMastersName`, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}

//  GET CLIENT ACORDING TO ITS  MASTERS 

export async function Get_Client_Acording_to_Its_master(data, token) {
    try {
        const res = await axios.post(`${Config.base_url}admin/getMasterAllChilds`, data, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}











// ----------------------------------------------  Childs   ---------------------------------------------------
//  Get All Clients
export async function admin_All_Childs(token) {
    try {
        const res = await axios.get(`${Config.base_url}admin/getUsers`, {
            headers: header(token),
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("error", err);
        // custom error
    }

}


