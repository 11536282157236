import React from 'react'
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";

import SuperDashboard from '../Layout/SuperAdmin/Dashboard/Dashboard';
import Sidebar from '../component/admin/Sidebar';
import Header from '../component/admin/Header';


import Admins from '../Layout/SuperAdmin/Admins/Admins';



const Admin = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const role_id = localStorage.getItem("Role")


    return (
        <>
            {location.pathname !== "/super" && location.pathname !== "/super/*" ? <Header /> : null}
            {location.pathname !== "/super" && location.pathname !== "/super/*" ? <Sidebar /> : null}

            <Routes>
                {/* <> */}
                <Route exact path="/dashboard" element={<SuperDashboard />} />
                <Route exact path="/admins" element={<Admins />} />
    
                {/* </> */}
            </Routes>


        </>
    )
}

export default Admin