import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { admin_All_Childs, admin_Add_Client, admin_All_Masters_Name, Find_One_Child_User, Update_master_or_child, admin_All_Masters, Remove__User } from "../../../Service/admin.service"

export const GetAdminAllChilds = createAsyncThunk("admin/childs", async (data, token) => {
  try {
    const res = await admin_All_Childs(data, token)
    if (res.status) {
      return await res?.data;
    }
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});


export const AddClients = createAsyncThunk("admin/client/add", async (data, token, rejectWithValue) => {
  const { req, AdminToken } = data
  try {
    const res = await admin_Add_Client(req, AdminToken)
    // console.log("error", res.response.status);
    // console.log("re", res.response.data.message);
    if (res.status) {
      return await res;
    }
    if (res.response.status == 400) {
      return await res.response.data.message
    }

  } catch (err) {


    // return await err?.response.data;

    console.log("error1", err);
    // custom error
  }
});

export const GetAllMastersName = createAsyncThunk("admin/getMastersName", async (data) => {
  const { AdminToken } = data

  try {
    const res = await admin_All_Masters_Name(AdminToken)

    // if (res.status) {
    return await res?.data;
    // } else {
    //   console.log("res", res);
    // }
  } catch (err) {
    console.log("error", err);
  }
});

export const FindOneChildUser = createAsyncThunk("admin/Find_One_Child_User", async (data) => {
  const { id, AdminToken } = data
  try {
    const res = await Find_One_Child_User(id, AdminToken)

    if (res.status) {
      return await res?.data;
    } else {
      console.log("res", res);
    }
  } catch (err) {
    console.log("error", err);
  }
});

export const Remove_One_User = createAsyncThunk("admin/Remove__User", async (data) => {
  const { id, token } = data
  try {
    const res = await Remove__User(id, token)

    if (res.status) {
      return await res?.data;
    } else {
      console.log("res", res);
    }
  } catch (err) {
    console.log("error", err);
  }
});


export const UpdateMasterChild = createAsyncThunk("admin/update", async (data, token) => {
  const { req, AdminToken } = data
  try {
    const res = await Update_master_or_child(req, AdminToken)
    return await res?.data;
  } catch (err) {
    console.log("error", err.message);
    // custom error
  }
});

export const GetAdminAllClients = createAsyncThunk("admin/clients", async (data, token) => {
  try {
    const res = await admin_All_Masters(data, token)
    return await res?.data;
  } catch (err) {
    console.log("error", err);
    // custom error
  }
});





const AdminChilds = createSlice({
  name: "AdminChilds",
  initialState: {
    isLoading: false,
    data: [],
    childlist: [],
    allmasters: [],
    masterlist: [],
    addchild: [],
    findOnchild: [],
    updateMasterUser: [],
    removeuser: [],
    isError: false,
    // status : false
  },

  recuders: {},
  extraReducers: {
    // For Get All Child List


    [GetAdminAllChilds.pending]: (state, action) => {
      console.log("Pending")
      // state.isLoading = true;
    },
    [GetAdminAllChilds.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, childlist: payload };
    },
    [GetAdminAllChilds.rejected]: (state) => {
      // state.isError = true;
      state.isLoading = true;

    },
    // For Get All Master List
    [GetAllMastersName.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      return { ...state, allmasters: payload };
    },

    // For Add Master And Child
    [AddClients.pending]: (state, action) => {
      console.log("Pending")
      // state.isLoading = true;
      return { ...state, addchild: [] };
    },
    [AddClients.fulfilled]: (state, action) => {
      state.isLoading = false;
      return { ...state, addchild: action.payload };
    },
    [AddClients.rejected]: (state, action) => {
      console.log("Pending", action)
      state.isLoading = true;
      return { ...state, addchild: [] };
    },
    [AddClients.fulfilled]: (state, action) => {
      state.isLoading = false;
      return { ...state, addchild: action.payload };
    },
    [FindOneChildUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      return { ...state, findOnchild: action.payload };
    },
    [UpdateMasterChild.fulfilled]: (state, action) => {
      state.isLoading = false;
      return { ...state, updateMasterUser: action.payload };
    },
    [GetAdminAllClients.fulfilled]: (state, action) => {
      state.isLoading = false;
      return { ...state, masterlist: action.payload };
    },
    [Remove__User.fulfilled]: (state, action) => {
      state.isLoading = false;
      return { ...state, removeuser: action.payload };
    },
  },
})

export const getChildlist = (state) => state.AdminChilds.childlist;
export const getMasters = (state) => state.AdminChilds.allmasters;
export const addChild = (state) => state.AdminChilds.addchild
export const findOnchild = (state) => state && state.AdminChilds.findOnchild.data
export const findOnchild1 = (state) => state && state.AdminChilds.findOnchild
export const masterlist = (state) => state && state.AdminChilds.masterlist;
export const removeusers = (state) => state && state.AdminChilds.removeuser;


export default AdminChilds;

