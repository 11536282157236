import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";



const ReusableForm = ({ initialValues, validationSchema, onSubmit, fieldtype, formik, btn_name }) => {

  const location = useLocation()

  const [passwordVisible, setPasswordVisible] = useState({});

  return (

    <form onSubmit={formik.handleSubmit}>
      <div className='row'>
        {/* <div className={`row`}> */}
        {fieldtype.map((field) => (
          <>

            <div className={` mx-auto  form-group mb-0 text-left col-lg-${field.name == "SegmentSelection" || field.name == "QuantitySelection" ? '6' : field.type == "checkbox" ? '3' : field.name == "setdemat_userid" || field.name == "setclient_code" || field.name == "useremail" || field.name == "userpassword" || field.name == "setapi_key" || field.name == "setapp_id" || field.name == "setapi_secret" || field.name == "setapi_type" ? "10"  : field.name == "forgetuseremail" || field.name == "newpassword" || field.name == "confirmpassword" ? "11" : field.name == "resconfirmpassword" || field.name == "resnewpassword" || field.name == "resoldpassword" ? "7" : '4'}`} key={field.name}>
              <label htmlFor={field.name}>
                {field.label} <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                {field.type === 'select' ? (
                  <select
                    id={field.name}
                    {...formik.getFieldProps(field.name)}
                    className=" form-control"
                  >
                    <option value="" selected disabled>
                      Please Select {field.name}
                    </option>
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  field.type == "checkbox" ? <>

                    <a style={{ cursor: "pointer" }} data-method="BUSD"
                      id="23" class="text-decoration-none"
                    >
                      <div class="rounded border">
                        <div
                          class="card-body    py-2 rounded-1  px-2  d-flex justify-content-between align-items-center" style={{ padding: "0.55rem !important", border: "1px solid #dee2e6" }}>
                          <span class="">
                            {field.name}
                          </span>
                          <span>
                            <input
                              id={field.name}
                              type={field.type}
                              {...formik.getFieldProps(field.name)}

                            />
                          </span>
                        </div>
                      </div>
                    </a>

                  </>
                    :
                    field.type == "password" ? <>
                      <input
                        id={field.name}
                        type={passwordVisible[field.name] ? 'text' : field.type}
                        placeholder={field.label}
                        {...formik.getFieldProps(field.name)}
                        className={` form-control`}

                      />
                      <i class={`fa-solid ${passwordVisible[field.name] ? 'fa-eye-slash' : 'fa-eye'}`} style={{
                        position: 'absolute',
                        top: '1.5px',
                        right: '0px',
                        padding: '12.4px 6.6px',
                        borderRadius: '3px'
                      }}
                        onClick={() => setPasswordVisible((prevState) => ({
                          ...prevState,
                          [field.name]: !prevState[field.name],
                        }))

                          // handleToggle(field.name)
                        }
                      ></i>
                    </>
                      :
                      <>
                        <input
                          id={field.name}
                          type={field.type}
                          placeholder={field.label == "DOB" ? "example - 10-08-1947" : field.label}
                          {...formik.getFieldProps(field.name)}
                          className={` form-control
                      // ${field.type = "checkbox" ? "" : 'form - control'}
                       `}
                       readOnly={field.name === "childusername" ||  field.name === "childemail" ||  field.name === "masterusername"  ||  field.name === "masteremail"  ? true : false}

                        />
                      </>
                )}
              </div>

              {formik.errors[field.name] && (
                <div style={{ color: 'red' }}>{formik.errors[field.name]}</div>
              )}
            </div >

          </>
        ))}
        {/* </div> */}

        {/* client/riskmgmt */}
        {/* {location.pathname !== "/child/riskmgmt" ? */}
        <div className="form-group mb-0">
          <button className={`btn btn-primary  ${location.pathname === "resetpassword" ? "col-md-11" : ""}`} type="submit">
            {btn_name}
          </button>
        </div>
        {/* : ""} */}

      </div >
    </form >
  );
};

export default ReusableForm;
